import { Line, mixins } from "vue-chartjs"

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartOptions: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.chartData) {
      this.customChartRender(this.chartData)
    }
  },
  methods: {
    customChartRender (val) {
      try {
        const options = this.chartOptions
        this.renderChart(val, options)
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    chartData (val) {
      this.customChartRender(val)
    }
  }
}
